<template>
  <div>
    <template v-for="(component, index) of content.column1" :key="index">
      <WidgetModel :component="component"></WidgetModel>
    </template>
  </div>
</template>

<script>
import WidgetModel from '@/components/Widgets/Shared/WidgetModel.vue';

export default {
  name: "AppLayoutDefault",
  data() {
    return {
      content: this.$route.meta && this.$route.meta.layout && this.$route.meta.layout.content  ? this.$route.meta.layout.content : '',
    };
  },
  components: {
    WidgetModel,
  },
}
</script>